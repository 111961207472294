body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-brand{
  color: #F4BC16;
  font-size: 50px;
  /* font-family: 'Adapter'; */
  font-weight: bold;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000; */
  margin:0 auto;
}

.nav-item .active {
  color: #444444;
  border-bottom-color: #444444;
}

.navbar-link{
  margin:0 auto;
}

.navbar-brand > img,
.navbar-brand > div{
  float: left;
}

.navbar-brand > div > span{
  display: block;
}

.navbar-brand > div > span.company{
  line-height: 56px;
}

.navbar-brand > div > span.slogan{
  font-size: 50%;
}

.navbar-brand > img{
  width: 90px;
  margin-right: 7px;
}

.nav-link{
  color: #444444;
  font-weight: bold;
  border-bottom: .25rem solid transparent
}


#slide{
  width: 100%;
  height: 250px;
}
 .swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}



#service{
  background: #F5F5F5;
}
.service-header{
  margin-bottom: 35px;
}

.service-img{
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
}
.service-title{
  font-weight: 500;
  font-size: 125%;
  text-align: center;
  padding: 22px 10px;
  background: #fff;
}

.service-header:after,
.service-title:after {
  display: block;
  content: "";
  background: #F4BC16;
  width: 40px;
  height: 2px;
  margin-top: 13px;
  margin-left: auto;
  margin-right: auto;

}

.service-description{
  text-align: center;
  padding: 5px 10px 22px 10px;
  background: #fff;
}

@media only screen and (min-width: 768px) {
  .navbar-brand,
  .navbar-link{
    margin: 0;
  }
  
}

@media only screen and (min-width: 1366px) {
  #slide{
    height: 400px;
  }
}

@media only screen and (min-width: 1440px) {
  #slide{
    height: 450px;
  }
}

@media only screen and (min-width: 1600px) {
  #slide{
    height: 600px;
  }
}


